<template>
  <div class="main-content">
    <page-title :name="$store.state.images.name" route_name="gallery"/>
    <div class="section">
      <div class="row">
        <div :key="index" class="col-one-third" v-for="(gallery, index) in $store.state.images.img_gallery_inner_not_paginate">
          <article class="post post-tp-8">
            <figure>
              <a :href="gallery.get_img.media_server_url + gallery.get_img.url" @click.prevent="fullImage(index)">
                <img :alt="gallery.name" :src="gallery.get_img.media_server_url + gallery.get_img.url" class="adaptive" style="object-fit: cover; height: 242px; width: 345px;">
              </a>
            </figure>
          </article>
        </div>
      </div>
      <div class="ya-share2-wrapper">
        <p>Поделиться: </p>
        <yandex-share :services="['vkontakte','facebook','odnoklassniki','twitter','whatsapp']"/>
      </div>
      <div class="gallery-modal" v-if="$store.state.images.img_gallery_inner_not_paginate && show_modal">
        <div @click="closeModal()" class="gallery-modal-background" title="Нажмите чтобы закрыть"></div>
        <div class="gallery-modal-wrapper">
          <div class="gallery-modal-image-container">
            <img :alt="$store.state.images.name" :src="modal_content.image" :title="$store.state.images.name" class="gallery-modal-image">
            <div class="gallery-modal-controls bvi-hide">
              <button @click="closeModal()" @touch="closeModal()" class="gallery-modal-close" title="Нажмите чтобы закрыть" type="button">
                <svg fill="none" height="20" viewBox="0 0 21 20" width="21" xmlns="http://www.w3.org/2000/svg">
                  <rect fill="white" height="25.6198" transform="matrix(0.701403 -0.712765 0.701403 0.712765 0.320312 1.73913)" width="2.43998"></rect>
                  <rect fill="white" height="25.6198" transform="matrix(-0.701403 -0.712765 -0.701403 0.712765 19.6816 1.73913)" width="2.43998"></rect>
                </svg>
              </button>
              <button @click="nextImage(0)" class="gallery-modal-controls-button left-button" title="Предыдущий элемент" type="button"></button>
              <button @click="nextImage(1)" class="gallery-modal-controls-button right-button" title="Следующий элемент" type="button"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '../components/layouts/PageTitle.vue';
import YandexShare from '@cookieseater/vue-yandex-share';

export default {
  name: 'Gallery',
  components: { PageTitle, YandexShare },
  async asyncData (context) {
    await context.store.dispatch('galleryPage', context.route.params.id);
  },
  metaInfo () {
    return this.$seo(this.$store.state.images.name);
  },
  data () {
    return {
      show_modal: false,
      modal_content: {
        image: '',
        active: 0,
        count: 0,
        base: ''
      }
    };
  },
  beforeMount () {
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 37) {
        this.nextImage(0);
      } else if (e.keyCode === 39) {
        this.nextImage(1);
      } else if (e.keyCode === 27) {
        this.closeModal();
      }
    });
  },
  methods: {
    /**
     * Переключение изображений в открытой галереи фотографий
     * @param direction - направление переключения
     */
    nextImage (direction) {
      let img = this.$store.state.images.img_gallery_inner_not_paginate;
      if (direction === 0) {
        this.modal_content.active--;
        if (img[this.modal_content.active] === undefined) {
          this.modal_content.active = this.$store.state.images.img_gallery_inner_not_paginate.length - 1;
        }
      } else {
        this.modal_content.active++;
        if (img[this.modal_content.active] === undefined) {
          this.modal_content.active = 0;
        }
      }
      this.modal_content.image = img[this.modal_content.active].get_img.media_server_url + '/' + img[this.modal_content.active].get_img.url;
    },
    /**
     * Вывод модалки с изображением
     * @param i - id изображения на которое кликнули
     */
    fullImage (i) {
      this.show_modal = true;
      this.modal_content.active = i;
      this.modal_content.image = this.$store.state.images.img_gallery_inner_not_paginate[i].get_img.media_server_url + '/' + this.$store.state.images.img_gallery_inner_not_paginate[i].get_img.url;
      this.checkShowModal();
    },
    closeModal () {
      this.show_modal = false;
      this.checkShowModal();
    },
    checkShowModal () {
        document.querySelector('.header-tp-4').style.zIndex = this.show_modal ? '1' : '2';
    }
  },
};
</script>
